import auth0 from "auth0-js";
import { Component } from "react";
//***************** Auth0  *************/
export class Auth extends Component {
  auth0 = new auth0.WebAuth({
    domain: "arieotechalertui.us.auth0.com",
    clientID: "qHWTcllK69rs00Uskpo5DUIQGYUhObuf",
    redirectUri: "https://dev-alertserviceui.arieotech.com/employeelist",
    responseType: "token id_token",
    scope: "openid profile email",
    audience: "https://arieotechalertui.us.auth0.com/api/v2/",
  });

  // To authorize the user to Auth0 page
  login = () => {
    this.auth0.authorize((err, res) => {});
  };

  //To handle authentication when user logged in or sign up
  handleAuth = () => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        //this.auth0.login();
        // console.log("Its failing at HandleAuth" + err.code);
        // console.log(err.error);
        // if (err.error === "unauthorized") {
        // }
      }
    });
  };

  //Setting up a session when user logged in
  setSession = (authResult) => {
    //set the time the access token will expire
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );

    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
  };

  //Authenticate user by time
  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  };

  //To logout from the session and remove the local storage values
  logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("sidebar");
    this.userProfile = null;

    this.auth0.logout({
      clientID: "qHWTcllK69rs00Uskpo5DUIQGYUhObuf",
      returnTo: "https://dev-alertserviceui.arieotech.com",
    });
  };

  //To get the access token sent by Auth0
  getAccessToken = () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      throw new Error("No access token found");
    }
    return accessToken;
  };
}

export default new Auth();
