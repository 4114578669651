import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import AddEditEmployee from "./AddEditEmployee";
import Auth from "./Auth/Auth";
import Sidebar from "./Common/Sidebar";
export default function EmployeeList(props) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [dataAvailable, setDataAvailable] = useState(true);
  const [toggleSideBar, setToggleSideBar] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);
  const [searchValue, setSearchValue] = useState([]);
  const [visible, setVisible] = useState(false);
  const [Id, setId] = useState(0);
  const [headerValue, setHeaderValue] = useState("");
  const [employeeCode, setEmplyeeCode] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const isAuthenticated = Auth.isAuthenticated();
    if (isAuthenticated) {
      setIsAuthenticated(true);
      getAllEmployee();
    } else {
      Auth.login({});
      setIsAuthenticated(false);
    }
  }, []);

  const ToggleSideBar = () => {
    setToggleSideBar(!toggleSideBar);
  };
  const getAllEmployee = () => {
    const token = localStorage.getItem("access_token");
    setSearchValue("");
    let config = {
      method: "get",
      url: process.env.REACT_APP_API + "GetEmployeeList",
      headers: { Authorization: `Bearer ${token}` },
    };

    setLoading(true);

    axios
      .request(config)
      .then((response) => {
        if (response.data.length > 0) {
          setDataAvailable(true);
        } else {
          setDataAvailable(false);
        }
        setLoading(false);

        setEmployeeList(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const onInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const onEditClick = (Id, mode) => {
    if (mode === "E") {
      setHeaderValue("Edit Employee");
      setId(Id);
      setVisible(true);
    } else {
      setHeaderValue("Add Employee");
      setId(Id);
      setVisible(true);
    }
  };

  const onSubmitClick = () => {
    if (Id === 0) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Emplyee created successfully!!!",
        life: 2000,
      });
    } else {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Emplyee updated successfully!!!",
        life: 2000,
      });
    }

    setVisible(false);
    getAllEmployee();
  };

  const accept = () => {
    const token = localStorage.getItem("access_token");
    let config = {
      method: "post",
      url:
        process.env.REACT_APP_API +
        `DeactivateEmployee?empCode=${employeeCode}&Flag=1`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Emplyee Deactivated successfully!!!",
          life: 2000,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    getAllEmployee();
  };

  const reject = () => {
    toast.current.show({
      severity: "error",
      summary: "Cancelled",
      detail: "Operation Cancelled",
      life: 3000,
    });
  };
  const confirm1 = (empCode, name) => {
    setEmplyeeCode(empCode);
    if (empCode !== 0 || empCode !== null || empCode !== undefined) {
      confirmDialog({
        message: `Are you sure you want to Deactivate ${name}?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept,
        reject,
      });
    }
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(employeeList);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "Employee");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(data, fileName + "_export_" + EXCEL_EXTENSION);
      }
    });
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <a>
          <img
            src={rowData.emp_ProfileImageURL}
            alt={"ProfileImage"}
            width="32"
          />
        </a>
      </div>
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex">
          <Button
            icon="pi pi-pencil"
            className="p-button-info mr-1"
            onClick={() => onEditClick(rowData.emp_code, "E")}
            title="Edit Employee"
          >
            <i></i>
          </Button>

          <div className="card flex flex-wrap gap-2 justify-content-center">
            <Button
              onClick={() => confirm1(rowData.emp_code, rowData.emp_FName)}
              icon="pi pi-check"
              label="Confirm"
              className="mr-0"
            ></Button>
          </div>
        </div>
      </React.Fragment>
    );
  };
  let spinnerDiv = (
    <div className="card-body" style={{ textAlign: "center" }}>
      <div
        className="d-flex table-loading"
        style={{ alignItems: "center", justifyContent: "center" }}
      >
        <ProgressSpinner animation="border" variant="warning" />
        <b>Loading Emplyees</b>
      </div>
    </div>
  );
  let tableData = (
    <>
      <DataTable
        value={employeeList}
        paginator
        rows={15}
        removableSort
        selectionMode="single"
        globalFilter={searchValue}
        stripedRows
        className="table table-striped table-hover   bg-white-table p-3 event-list table-responsive"
      >
        <Column header="Profile Image" body={imageBodyTemplate}></Column>
        <Column field="emp_code" header="Code" sortable></Column>
        <Column field="emp_FName" header="First Name" sortable></Column>
        <Column field="emp_LName" header="Last Name" sortable></Column>
        <Column field="emp_email" header="Email" sortable></Column>
        <Column header="Action" body={actionBodyTemplate} />
      </DataTable>
    </>
  );
  return (
    <>
      {isAuthenticated ? (
        <main className="main-content-wrapper dashboard">
          <Toast ref={toast} />
          <div className="page-body-wrapper">
            <Sidebar toggleSideBar={ToggleSideBar} />
            <div className="page-body">
              <h4>Employee List</h4>
              <section className="content bg-white pt-2 pb-3 event-table">
                <div className="card-outline card-primary">
                  <div className="box">
                    <div className="box-body">
                      {dataAvailable ? (
                        <>
                          {loading ? null : (
                            <>
                              <div className="col-lg-12">
                                <div className="userevent-flex container my-2">
                                  <div className="row ">
                                    <div className="col-md-5">
                                      <span className="p-input-icon-right">
                                        <i className="pi pi-search" />
                                        <InputText
                                          type="search"
                                          onInput={onInputChange}
                                          placeholder="Search by name"
                                          style={{
                                            padding: "10px 60px",
                                            //width: "300%",
                                          }}
                                        />
                                      </span>
                                    </div>

                                    <div className="col-md-2">
                                      <Button
                                        label="Add"
                                        icon="pi pi-plus"
                                        onClick={() => onEditClick(0, "C")}
                                      />
                                    </div>

                                    <div className="col-md-2">
                                      <Button
                                        label="Refresh"
                                        icon="pi pi-refresh"
                                        severity="info"
                                        onClick={() => getAllEmployee()}
                                      />
                                    </div>
                                    <div className="col-md-1"></div>

                                    <div className="col-md-2">
                                      <Button
                                        label="Export"
                                        type="button"
                                        icon="pi pi-file-excel"
                                        severity="success"
                                        rounded
                                        onClick={exportExcel}
                                        data-pr-tooltip="XLS"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <br></br>
                              </div>
                            </>
                          )}
                          <div className="">
                            {loading ? spinnerDiv : tableData}
                          </div>
                        </>
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            paddingTop: "5px",
                            color: "black",
                          }}
                        >
                          <b>
                            Oops ! No Employees found. Kindly create one by
                            clicking on the Add Employee button displayed on
                            screen
                          </b>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </section>
              <Dialog
                header={headerValue}
                visible={visible}
                style={{ width: "45vw" }}
                onHide={() => setVisible(false)}
                //footer={footerContent}
              >
                <AddEditEmployee
                  Id={Id}
                  onSubmitClick={() => {
                    onSubmitClick();
                  }}
                  setVisible={() => {
                    setVisible(false);
                  }}
                />
              </Dialog>
              <ConfirmDialog />
            </div>
          </div>
        </main>
      ) : (
        <></>
      )}
    </>
  );
}
