import axios from "axios";
import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
export default function AddEditEmployee(props) {
  let today = new Date();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [NewImageValue, setNewImageValue] = useState(
    "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
  );
  const [employeeCode, setEmplyeeCode] = useState(0);
  const [empFirstName, setEmpFirstName] = useState("");
  const [empLastName, setEmpLastName] = useState("");

  const [date, setBirthDate] = useState(null);
  const [dateOfJoining, setJoiningDate] = useState(null);
  const [designation, setDesignation] = useState("");
  const [officialEmail, setofficialEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fileName, setFileName] = useState("");
  const [imageValue, setImageValue] = useState("");
  const [invalidClassNameForEmpCode, setInvalidClassNameForEmpCode] =
    useState(false);
  const [invalidClassNameForFName, setInvalidClassNameForFName] =
    useState(false);
  const [invalidClassNameForLName, setInvalidClassNameForLName] =
    useState(false);
  const [invalidClassNameForDOB, setInvalidClassNameForDOB] = useState(false);
  const [invalidClassNameForDOJ, setInvalidClassNameForDOJ] = useState(false);
  const [invalidClassNameForDesignation, setInvalidClassNameForDesignation] =
    useState(false);
  const [invalidClassNameForEmail, setInvalidClassNameForEmail] =
    useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  useEffect(() => {
    if (props.Id !== 0) {
      GetEmployeeByEmpCode(props.Id);
    }
  }, []);
  const GetEmployeeByEmpCode = (Id) => {
    const token = localStorage.getItem("access_token");
    let config = {
      method: "get",
      url: process.env.REACT_APP_API + `GetEmployeeByEmpCode?empCode=${Id}`,
      headers: { Authorization: `Bearer ${token}` },
    };

    setLoading(true);

    axios
      .request(config)
      .then((response) => {
        setEmplyeeCode(response.data.emp_code);
        setEmpFirstName(response.data.emp_FName);
        setEmpLastName(response.data.emp_LName);
        setofficialEmail(response.data.emp_email);
        setDesignation(response.data.emp_designation);
        const dobStr = response.data.emp_dob;
        const dobObject = new Date(dobStr);
        setBirthDate(dobObject);
        const dojStr = response.data.emp_doj;
        const dojObject = new Date(dojStr);
        setJoiningDate(dojObject);
        response.data.emp_ProfileImageName === null
          ? setNewImageValue(NewImageValue)
          : setNewImageValue(response.data.emp_ProfileImageName);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const onImageChange = (e) => {
    setFileName(e.target.files[0].name);
    setNewImageValue(URL.createObjectURL(e.target.files[0]));
    setImageValue(e.target.files[0]);
    setIsImageUploaded(true);
  };

  const validate = () => {
    var result = true;
    if (
      employeeCode === 0 ||
      employeeCode === null ||
      employeeCode === undefined
    ) {
      setInvalidClassNameForEmpCode(true);
      setInvalidClassNameForDOB(false);
      setInvalidClassNameForDOJ(false);
      setInvalidClassNameForFName(false);
      setInvalidClassNameForLName(false);
      setInvalidClassNameForDesignation(false);
      setInvalidClassNameForEmail(false);
      setErrorMessage("Employee code is required");
      return false;
    } else if (
      empFirstName === "" ||
      empFirstName === null ||
      empFirstName === undefined
    ) {
      setInvalidClassNameForFName(true);
      setInvalidClassNameForDOB(false);
      setInvalidClassNameForDOJ(false);
      setInvalidClassNameForEmpCode(false);
      setInvalidClassNameForLName(false);
      setInvalidClassNameForDesignation(false);
      setInvalidClassNameForEmail(false);
      setErrorMessage("First name is required");
      return false;
    } else if (
      empLastName === "" ||
      empLastName === null ||
      empLastName === undefined
    ) {
      setInvalidClassNameForLName(true);
      setInvalidClassNameForDOB(false);
      setInvalidClassNameForDOJ(false);
      setInvalidClassNameForEmpCode(false);
      setInvalidClassNameForFName(false);
      setInvalidClassNameForDesignation(false);
      setInvalidClassNameForEmail(false);
      setErrorMessage("Last name is required");
      return false;
    } else if (date === "" || date === null || date === undefined) {
      setInvalidClassNameForDOB(true);
      setInvalidClassNameForDOJ(false);
      setInvalidClassNameForEmail(false);
      setInvalidClassNameForEmpCode(false);
      setInvalidClassNameForFName(false);
      setInvalidClassNameForLName(false);
      setInvalidClassNameForDesignation(false);
      setErrorMessage("Birth date is required");
      return false;
    } else if (
      dateOfJoining === "" ||
      dateOfJoining === null ||
      dateOfJoining === undefined
    ) {
      setInvalidClassNameForDOJ(true);
      setInvalidClassNameForDOB(false);
      setInvalidClassNameForEmail(false);
      setInvalidClassNameForEmpCode(false);
      setInvalidClassNameForFName(false);
      setInvalidClassNameForLName(false);
      setInvalidClassNameForDesignation(false);
      setErrorMessage("Joining date is required");
      return false;
    } else if (
      designation === "" ||
      designation === null ||
      designation === undefined
    ) {
      setInvalidClassNameForDesignation(true);
      setInvalidClassNameForDOJ(false);
      setInvalidClassNameForDOB(false);
      setInvalidClassNameForEmpCode(false);
      setInvalidClassNameForFName(false);
      setInvalidClassNameForLName(false);
      setInvalidClassNameForEmail(false);
      setErrorMessage("Designation is required");
      return false;
    } else if (
      officialEmail === "" ||
      officialEmail === null ||
      officialEmail === undefined
    ) {
      setInvalidClassNameForEmail(true);
      setInvalidClassNameForDOJ(false);
      setInvalidClassNameForDOB(false);
      setInvalidClassNameForEmpCode(false);
      setInvalidClassNameForFName(false);
      setInvalidClassNameForLName(false);
      setInvalidClassNameForDesignation(false);
      setErrorMessage("Email is required");
      return false;
    }
    return result;
  };
  const createEmployee = () => {
    setInvalidClassNameForEmail(false);
    setInvalidClassNameForDOJ(false);
    setInvalidClassNameForDOB(false);
    setInvalidClassNameForEmpCode(false);
    setInvalidClassNameForFName(false);
    setInvalidClassNameForLName(false);
    setInvalidClassNameForDesignation(false);
    if (validate()) {
      setSubmitLoading(true);

      let data = JSON.stringify({
        emp_code: employeeCode,
        emp_FName: empFirstName,
        emp_LName: empLastName,
        emp_email: officialEmail,
        emp_designation: designation,
        emp_ProfileImageName: fileName,
        emp_dob: moment(date).format("YYYY-MM-DD"),
        emp_doj: moment(dateOfJoining).format("YYYY-MM-DD"),
        isRemoved: false,
      });
      const token = localStorage.getItem("access_token");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_API + "InsertEmployee",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (isImageUploaded) {
            uploadImageToS3Bucket(employeeCode);
          }
          props.onSubmitClick();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const updateEmployee = () => {
    // console.log(moment(date).format());
    // console.log(moment(date).format("L"));
    // console.log(moment.utc(date).local().format("L"));

    if (validate()) {
      setSubmitLoading(true);
      setInvalidClassNameForEmail(false);
      setInvalidClassNameForDOJ(false);
      setInvalidClassNameForDOB(false);
      setInvalidClassNameForEmpCode(false);
      setInvalidClassNameForFName(false);
      setInvalidClassNameForLName(false);
      setInvalidClassNameForDesignation(false);

      let data = JSON.stringify({
        emp_code: employeeCode,
        emp_FName: empFirstName,
        emp_LName: empLastName,
        emp_email: officialEmail,
        emp_designation: designation,
        emp_dob: moment(date).format("YYYY-MM-DD"),
        emp_doj: moment(dateOfJoining).format("YYYY-MM-DD"),
        isRemoved: false,
      });

      const token = localStorage.getItem("access_token");
      let config = {
        method: "post",
        url: process.env.REACT_APP_API + `UpdateEmployee?empCode=${props.Id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          if (isImageUploaded) {
            uploadImageToS3Bucket(employeeCode);
          }
          props.onSubmitClick();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const uploadImageToS3Bucket = (empCode) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("FormFile", imageValue);
    const token = localStorage.getItem("access_token");
    var config = {
      method: "put",
      url: process.env.REACT_APP_API + `UploadProfileImage?empCode=${empCode}`,
      headers: {
        ContentType: "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    setSubmitLoading(true);

    axios(config)
      .then((response) => {
        setIsImageUploaded(false);
        setSubmitLoading(false);
      })
      .catch((error) => {
        setIsImageUploaded(false);
        setSubmitLoading(false);
      });
  };

  const onSubmitClick = () => {
    if (props.Id === 0) {
      createEmployee();
    } else {
      updateEmployee();
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ marginRight: "10px" }}
      />
      <Button
        label="Submit"
        icon="pi pi-check"
        onClick={onSubmitClick}
        autoFocus
        loading={submitLoading}
        style={{ marginRight: "10px" }}
      />

      {errorMessage !== "" ? (
        <Message severity="error" text={errorMessage} />
      ) : (
        <></>
      )}
    </div>
  );
  let spinnerDiv = (
    <div className="card-body" style={{ textAlign: "center" }}>
      <div
        className="d-flex table-loading"
        style={{ alignItems: "center", justifyContent: "center" }}
      >
        <ProgressSpinner animation="border" variant="warning" />
        <b>Loading Emplyee details</b>
      </div>
    </div>
  );
  return (
    <>
      <div>
        <Toast ref={toast} />
        {loading ? (
          spinnerDiv
        ) : (
          <>
            <div className="container-fluid">
              <div className="row edit-profile-mobile-view">
                <div className="col-lg-12">
                  <div className="userevent-flex container my-2">
                    <div className="row ">
                      <div className="col-md-6">
                        <span className="p-float-label">
                          {/* <Dropdown
                        filter
                        virtualScrollerOptions={{ itemSize: 38 }}
                        showClear
                        inputId="dd-city"
                        value={selectedDesignation}
                        onChange={(e) => setSelectedDesignation(e.value)}
                        options={designations}
                        optionLabel="designationName"
                        className="w-full md:w-10rem"
                        style={{
                          width: "100%",
                        }}
                      /> */}
                          <InputText
                            id="designationName"
                            value={designation}
                            onChange={(e) => setDesignation(e.target.value)}
                            className={
                              invalidClassNameForDesignation
                                ? "p-invalid"
                                : "p mr-2"
                            }
                          />
                          <label htmlFor="dd-city">Designation </label>
                        </span>
                      </div>
                      <div className="col-md-6">
                        <span className="p-float-label">
                          <InputText
                            id="email"
                            value={officialEmail}
                            onChange={(e) => setofficialEmail(e.target.value)}
                            className={
                              invalidClassNameForEmail ? "p-invalid" : "p mr-2"
                            }
                            style={{ width: "120%" }}
                          />
                          <label htmlFor="email">Official Email</label>
                        </span>
                      </div>

                      {/* <div className="col-md-4">
                    <span className="p-float-label">
                      <Button
                        label="Add Designation"
                        severity="help"
                        onClick={ShowDesignationPopUp}
                      />
                    </span>
                  </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="userevent-flex container my-2">
                    <div className="row ">
                      <div className="col-md-6">
                        <span className="p-float-label">
                          <Calendar
                            dateFormat="dd/mm/yy"
                            value={date}
                            onChange={(e) => setBirthDate(e.value)}
                            maxDate={today}
                            readOnlyInput
                            className={
                              invalidClassNameForDOB ? "p-invalid" : ""
                            }
                            //style={{ zIndex: 0 }}
                          />
                          <label htmlFor="birth_date">Birth Date</label>
                        </span>
                      </div>

                      <div className="col-md-6">
                        <span className="p-float-label">
                          <Calendar
                            dateFormat="dd/mm/yy"
                            value={dateOfJoining}
                            onChange={(e) => setJoiningDate(e.value)}
                            maxDate={today}
                            readOnlyInput
                            className={
                              invalidClassNameForDOJ ? "p-invalid" : ""
                            }
                            style={{
                              padding: "1px 3px",
                              width: "108%",
                            }}
                          />
                          <label htmlFor="dateOfJoining">Joining Date</label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <br></br>
                </div>
                <div className="col-lg-12">
                  <div className="userevent-flex container my-2">
                    <div className="row ">
                      <div className="col-md-6">
                        <span className="p-float-label">
                          <InputText
                            id="empFirstName"
                            value={empFirstName}
                            onChange={(e) => setEmpFirstName(e.target.value)}
                            className={
                              invalidClassNameForFName ? "p-invalid" : "p mr-2"
                            }
                          />
                          <label htmlFor="empFirstName">First Name</label>
                        </span>
                      </div>

                      <div className="col-md-6">
                        <span className="p-float-label">
                          <InputText
                            id="empLastName"
                            value={empLastName}
                            onChange={(e) => setEmpLastName(e.target.value)}
                            className={
                              invalidClassNameForLName ? "p-invalid" : "p mr-2"
                            }
                          />
                          <label htmlFor="empLastName">Last Name</label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <br></br>
                </div>
                <div className="col-lg-12">
                  <div className="userevent-flex container my-2">
                    <div className="row ">
                      <div className="col-md-12">
                        <span className="p-float-label">
                          <InputNumber
                            inputId="integeronly"
                            value={employeeCode}
                            onValueChange={(e) => setEmplyeeCode(e.value)}
                            disabled={props.Id === 0 ? false : true}
                            className={
                              invalidClassNameForEmpCode ? "p-invalid" : ""
                            }
                          />
                          <label htmlFor="employeeCode">Employee Code</label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <br></br>
                </div>
                <div className="col-lg-12 ">
                  <div className="userevent-flex container my-2">
                    <div className="row ">
                      <div className="col-md-6">
                        <div>
                          <img
                            alt="ProfileImage"
                            className="edit-user-profile"
                            src={NewImageValue}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <input
                          accept="image/*"
                          type="file"
                          onChange={onImageChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {footerContent}
          </>
        )}
      </div>
    </>
  );
}
