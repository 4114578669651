import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../App.css";
import Auth from "../../Auth/Auth";
function LoggingIn() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    var access_token = localStorage.getItem("access_token");
    if (access_token !== null && access_token !== undefined) {
      setLoading(false);
    } else {
      Auth.login();
    }
  }, []);

  return (
    <>
      {loading ? (
        <>
          <main className="main-content">
            <div
              className="table-loading"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontFamily: "primeicon",
                fontSize: "16px",
                fontWeight: "700px",
                color: "black",
              }}
            >
              <ProgressSpinner animation="border" variant="primary" />
              <p>Logging in</p>
            </div>
          </main>
        </>
      ) : (
        <div className="App">
          <header className="App-header">
            <h4>You are logged in successfully!!!</h4>
            <Link to="/employeelist">Go to Dashboard</Link>
          </header>
        </div>
      )}
    </>
  );
}

export default LoggingIn;
