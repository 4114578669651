import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import arieotechBigLogo from "../../Images/arieotechBigLogo.png";
import arieotechBigLogo1 from "../../Images/arieotechBigLogo1.jpg";
import arieotechLogo from "../../Images/arieotechLogo.png";
import boxArrowDown from "../../Images/box-arrow-down.png";
import Auth from "../Auth/Auth";
export default function Sidebar(props) {
  const [toggleSideBarNav, setToggleSideBarNav] = useState(false);
  const onSideBarToggle = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 767.98) {
      setToggleSideBarNav(!toggleSideBarNav);
      localStorage.setItem("sidebar", toggleSideBarNav);
      if (toggleSideBarNav) {
        document.body.classList.remove("toggleClass");
        document.getElementsByClassName("page-body-wrapper")[0].className =
          "page-body-wrapper";
      } else {
        document.body.classList.add("toggleClass");
        document.getElementsByClassName("page-body-wrapper")[0].className =
          "page-body-wrapper toggleNav";
      }
    } else {
      localStorage.setItem("sidebar", toggleSideBarNav);
      if (toggleSideBarNav) {
        document.body.classList.remove("toggleClass");
        document.getElementsByClassName("page-body-wrapper")[0].className =
          "page-body-wrapper";
      } else {
        document.body.classList.add("toggleClass");
        document.getElementsByClassName("page-body-wrapper")[0].className =
          "page-body-wrapper toggleNav";
      }
      setToggleSideBarNav(!toggleSideBarNav);
    }
  };

  const onSideBarToggle1 = (ToggleSideBarNav) => {
    if (ToggleSideBarNav === 0) {
      localStorage.setItem("sidebar", "false");
    } else {
      localStorage.setItem("sidebar", "true");
    }
    if (ToggleSideBarNav) {
      document.body.classList.remove("toggleClass");
      document.getElementsByClassName("page-body-wrapper")[0].className =
        "page-body-wrapper";
    } else {
      document.body.classList.add("toggleClass");
      document.getElementsByClassName("page-body-wrapper")[0].className =
        "page-body-wrapper toggleNav";
    }
    setToggleSideBarNav(!ToggleSideBarNav);
  };
  useEffect(() => {
    let sidebar = localStorage.getItem("sidebar");
    if (sidebar === undefined || sidebar === null) {
      sidebar = false;
    }
    let windowWidth = window.innerWidth;
    if (sidebar === "false") {
      onSideBarToggle1(0);
    } else {
      if (windowWidth < 767.98) {
        onSideBarToggle1(0);
      } else {
        onSideBarToggle1(1);
      }
    }
  }, []);
  return (
    <>
      <div className="sidebar-wrapper">
        <div className="logo-wrapper">
          <a href="/dashboard" className="logo" title="ArieotechAlert">
            <img
              src={arieotechBigLogo1}
              width=""
              height=""
              alt="ArieotechAlert"
              className="logo-desktop "
            />
            <img
              src={arieotechLogo}
              className="logo-toggle  "
              width=""
              height=""
              alt=""
            />
          </a>
        </div>
        <div className="toggle-sidebar mobile-hide">
          <div onClick={onSideBarToggle}>
            <span className="one"></span>
            <span className="two"></span>
            <span className="three"></span>
          </div>
        </div>
        <br />
        <br />
        <br />

        <div class="sidebar-menu" style={{ marginTop: "20px" }}>
          <ul class="sidebar-links1 mt-1" style={{}}>
            <li>
              <Link
                onClick={() => {
                  Auth.logout();
                }}
                to="/"
                class="sidebar-link"
                title="logout"
              >
                <img
                  src={boxArrowDown}
                  class="pr-2"
                  width=""
                  height=""
                  alt=""
                />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
