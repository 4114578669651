import "primereact/resources/primereact.min.css";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Auth from "./Screens/Auth/Auth";
import LoggingIn from "./Screens/Common/AccountManagement/LoggingIn";
import EmployeeList from "./Screens/EmployeeList";
function App() {
  useEffect(() => {
    Auth.handleAuth();
  });
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<EmployeeList />}></Route>
          <Route path="/Loggingin" element={<LoggingIn />} />
          <Route exact path="/employeelist" element={<EmployeeList />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
